<script>
import appConfig from "@/app.config";
import {http} from '@/helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main"
import PageHeader from "@/components/page-header";
import List from "./list";
import Insert from "./insert";
import Edit from "./edit";
import Swal from "sweetalert2";

  export default {
    page: {
      title: 'Lista de Profissionais',
      meta: [{
        name: 'description',
        content: appConfig.description
      }],
    },
    components: {List,Insert,Edit,PageHeader,Layout},
    data() {
      return {
        currentProfessional: {
          id: null,
          name: null,
          email: null,
          contact: null,
          council: null,
          credential: null,
          specialty: [],
          user_id: null,
          empresa_id: null,
        },
        professionals: [],
        inserindo: false,
        editando: false,
        titleBody: 'Lista de Profissionais',
        items: [{
            text: "Dashboard",
            href: "/",
          },
          {
            text: "Profissionais",
            href: "/",
            active: true,
          },
          {
            text: "Incluir Profissional",
            href: "/cadastros/profissionais",
            active: true,
          }
        ],
        users: [],
        vet_tratamentos: [],
      }
    },
    computed: {
      isHide() {
        return (!this.inserindo && !this.editando);
      },
    },
    created() {
      this.currentUser = JSON.parse(localStorage.getItem('user'));
      this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
    },
    mounted() {
    this.getData();
    },
    methods: {
      makeToast(variant = null, message = 'Error generic') {
        this.counter++;
        this.$bvToast.toast(message, {
          title: `Notificação`,
          toaster: 'b-toaster-bottom-right',
          variant: variant,
          solid: true,
          appendToast: true
        });
      },
      changeHomeTitle(newTitle) {
        this.titleBody = newTitle;
      },
      async back() {
        await this.getData();
        this.inserindo = false;
        this.editando = false;
      },
      onLoader() {
        if (document.getElementById("preloader").style.display === "none") {
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "block";
        }
      },
      offLoader() {
        if (document.getElementById("preloader").style.display === "block") {
          setTimeout(function() {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
          }, 2500);
        }
      },
      getData() {
        // Exibe o SweetAlert com loader
        Swal.fire({
          title: 'Aguarde...',
          text: 'Carregando dados...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          }
        });

        const promisse = http.get('/professional?empresa_id=' + this.currentEmpresa.id);

        return promisse.then((response) => {
          this.professionals = response.data;

          return this.professionals || [];
        }).catch((error) => {
          console.log(error);
          this.makeToast('danger', error.response);
        }).finally(() => {
          Swal.close(); // Fecha o loader
        });
      },
      insert() {
        this.inserindo = !this.inserindo;
      },
      async edit(value) {
        const result = await this.get_tratamet();
        console.log(result);

        // Exibe o SweetAlert com loader
        Swal.fire({
          title: 'Aguarde...',
          text: 'Carregando dados...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          }
        });

        let promisse = http.get(`/empresa/usersbyempresa/` + this.currentEmpresa.id);

        return promisse.then((response) => {
          this.users = response.data;
          return this.users;
        }).catch((error) => {
          console.log(error);
          Swal.close(); // Fecha o loader
          Swal.fire('Oops...', error.response.data, 'error');
        }).finally(() => {
          Swal.close(); // Fecha o loader
          this.currentProfessional = value;
          this.editando = !this.editando;
        });
      },
      doPost(professional) {
        // Exibe o SweetAlert com loader
        Swal.fire({
          title: 'Aguarde...',
          text: 'Gravando dados do Profissional...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          }
        });

        professional.empresa_id = this.currentEmpresa.id;
        let promisse = http.post('/professional?empresa_id=' + this.currentEmpresa.id, professional);
        promisse.then((response) => {
          if (response.status === 200) {
            this.successtimermsg();
            this.professionals.push(response.data);
            this.back();
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.close(); // Fecha o loader
          Swal.fire('Oops...', error.response.data, 'error');
        }).finally(() => {
        });
      },
      doPut(professional) {
        // Exibe o SweetAlert com loader
        Swal.fire({
          title: 'Aguarde...',
          text: 'Atualizando dados do Profissional...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          }
        });

        professional.user_id = this.currentUser.id;
        const promisse = http.put('/professional/' + professional.id + '?empresa_id=' + this.currentEmpresa.id, professional);

        return promisse.then((response) => {
          if (response.status === 200) {
            console.log(professional);
            this.professionals[this.professionals.indexOf(professional)] = professional;
            return this.professionals;
          }
        }).catch((error) => {
          console.log(error);
          this.makeToast('danger', error.response);
        }).finally(() => {
          Swal.close(); // Tira o loader
          this.back();
        });
      },
      
      doDelete(professional) {
        Swal.queue([
        {
          title: "CONFIRME",
          icon: "warning",
          html: "Deseja realmente excluir o <b>Profissional</b>?",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          cancelButtonText: "Nao",
          confirmButtonText: "Sim, excluir!",
          showLoaderOnConfirm: true,
          preConfirm: () => {

            professional.empresa_id = this.currentEmpresa.id;
            let promisse = http.delete('/professional/' + professional.id + '?empresa_id=' + this.currentEmpresa.id, professional);
            return promisse.then((response) => {
              if (response.status === 200) {
                // this.professionals.splice(this.professionals.indexOf(professional), 1);
                Swal.insertQueueStep({
                  type: "success",
                  icon: "success",
                  title: "Sucesso!",
                  text: "Profissinal exclluído com sucesso."

                });
              }
            })
            .catch((error) => {
              console.log(error);
              Swal.insertQueueStep({
                  type: "error",
                  icon: "warning",
                  title: "Erro ao excluir o Profissional",
                  text: error.response.data
                });
            });
          }
        }
      ]);
      },
      get_tratamet() {
        // Exibe o SweetAlert com loader
        Swal.close(); // Fecha o loader
        Swal.fire({
          title: 'Aguarde...',
          text: 'Carregando dados Especialidades...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
          }
        });

        const promisse = http.get('/specialty/pluck?empresa_id=' + this.currentEmpresa.id);
        return promisse.then((response) => {
          this.vet_tratamentos = response.data;
          return this.vet_tratamentos;
        }).catch((error) => {
          console.log(error);
          Swal.close(); // Fecha o loader
          Swal.fire('Oops...', error.response.data, 'error');
        }).finally(() => {
        });
      },
      successmsg() {
        Swal.fire("Sucesso!", "Registro gravado com sucesso!", "success");
      },
      successtimermsg(msg) {
        let timerInterval;
        Swal.fire({
          title: "Atenção",
          html: msg ? msg : "Dados gravados com sucesso!",
          timer: 2000,
          timerProgressBar: true,
          onClose: () => {
            clearInterval(timerInterval);
          }
        }).then(result => {
          if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.timer
          ) {
            console.log("I was closed by the timer"); // eslint-disable-line
          }
        });
      },
    },
  }
</script>

<template>
  <!-- <div class="row">
    <div class="col-md-12">
      <div class="card">
      
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Plano de Contas</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
        
        <div class="card-body" v-if="!isHide">
          <Insert
           v-if="inserindo"
           :currentEmpresa="currentEmpresa"
           @newTitle="changeHomeTitle"
           @doPost="doPost "
          />
          <Edit
           v-if="editando"
           :currentEmpresa="currentEmpresa"
           :oldLocalEstoque="currentLocalEstoque"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
          />
        </div>
        <List v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :listResponsibles="professionals"
         :hide="isHide"
         @newTitle="changeHomeTitle"
         @edit="edit"
         @doDelete="doDelete"
        />
      </div>
    </div>
  </div> -->
  <Layout> 
  <PageHeader :title="titleBody" :items="items" />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
      
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6"></div>
            <div class="col-sm-12 col-md-6 text-md-right">
              <button @click="insert()" v-if="isHide" type="button" class="btn btn-success">+ Incluir Profissional</button>
              <button @click="back()" v-if="!isHide" class="btn btn-secondary">Voltar</button>
            </div>
          </div>
        </div>
        
        <div class="card-body" v-if="!isHide">
          <Insert
           v-if="inserindo"
           :currentEmpresa="currentEmpresa"
           :users="users"
           :vet_tratamentos="vet_tratamentos"
           @newTitle="changeHomeTitle"
           @doPost="doPost"
           @offLoader="offLoader"
           @onLoader="onLoader"
          />
          <Edit
           v-if="editando"
           :currentEmpresa="currentEmpresa"
           :currentProfessional="currentProfessional"
           :users="users"
           :vet_tratamentos="vet_tratamentos"
           @newTitle="changeHomeTitle"
           @doPut="doPut"
           @offLoader="offLoader"
           @onLoader="onLoader"
          />
        </div>
        <List v-if="isHide"
         :currentEmpresa="currentEmpresa"
         :professionals="professionals"
         :hide="isHide"
         @newTitle="changeHomeTitle"
         @edit="edit"
         @doDelete="doDelete"
        />
      </div>
    </div>
  </div>
  </Layout> 
</template>