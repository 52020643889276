<script>
export default {
  props: {
    currentProfessional: { type: Object },
  },
  data() {
    return {
      professional: {},      
    }
  },
  mounted() {
    this.professional = this.currentProfessional;
  }
}
</script>

<template>
<div class="professional">
  <p>ID: {{ professional.id }}</p>
  <p>Nome: {{ professional.name }}</p>
  <p>E-mail: {{ professional.email }}</p>
  <p>Contato: {{ professional.contact }}</p>
  <p>Especialidade: {{ professional.specialty }}</p>
  <p>Criado: <b-badge data-name="created_at" class="field-created_at" variant="secondary">{{ formatDateTimeBR(professional.created_at) }}</b-badge></p>
  <p>Última alteração: <b-badge data-name="updated_at" class="field-updated_at" variant="secondary">{{ formatDateTimeBR(professional.updated_at) }}</b-badge></p>
</div>
</template>