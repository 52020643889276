<script>
import Detail from "./detail";

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    professionals: { type: Array, required: true },
    hide: { type: Boolean, required: true }
  },
  components: { Detail },
  data() {
    return {
      titleBody: 'Listagem dos Pacientes',
      currentProfessional: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "ID", key: "id", sortable: true, tdClass: 'text-center', thClass: 'text-center' },
        { label: 'Nome', key: 'name', sortable: true, thClass: 'text-center' },
        { label: "E-mail", key: "email", sortable: true, thClass: 'text-center' },
        { label: "Celular", key: "contact", sortable: true, tdClass: 'text-right', thClass: 'text-center' },
        { label: "Especialidade", key: "specialty", sortable: true, tdClass: 'text-center', thClass: 'text-center' },
        { label: "Ações", key: "acoes", sortable: false, tdClass: 'text-center', thClass: 'text-center' }
      ],
      hide1: false,
      isShow: false,
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return (this.professionals.length) ? this.professionals.length : 0;
    },
    clonefoot() {
      return (this.professionals.length || this.professionals.length > 10) ? true : false;
    },
  },
  mounted() {
   // this.$emit('newTitle', this.titleBody);
  },
  created(){
      this.hide1 = this.hide
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    editar(currentProfessional) {
      this.$emit('edit', currentProfessional);
    },
    excluir(professional) {
      this.$emit('doDelete', professional);
    },
    setProfessional(currentProfessional) {
      console.log(currentProfessional);
      this.currentProfessional = currentProfessional;
    },
    view(currentProfessional) {
      this.currentProfessional = currentProfessional;
      this.isShow = true;
    }
  },
}
</script>

<template>
<div class="card-body" v-if="hide1">
  <div class="row mt-4">
    <!-- Start Limit -->
    <div class="col-sm-12 col-md-6">
      <div id="responsible-table_length" class="dataTables_length">
        <label class="d-inline-flex align-items-center">
          Exibir&nbsp;
          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
        </label>
      </div>
    </div>
    <!-- End Limit -->
    <!-- Start Search -->
    <div class="col-sm-12 col-md-6">
      <div id="responsible-table_filter" class="dataTables_filter text-md-right">
        <label class="d-inline-flex align-items-center">
          Pesquisar:
          <b-form-input
          v-model="filter"
          type="search"
          placeholder="Pesquisar..."
          class="form-control form-control-sm ml-2"
          ></b-form-input>
        </label>
      </div>
    </div>
    <!-- End search -->
  </div>
   <!-- Start Table -->
   <div class="row mt-4">
    <div v-if="hide" class="table-responsive mb-0">
      <b-table
       :items="professionals"
       :fields="fields"
       responsive="sm"
       :per-page="perPage"
       :current-page="currentPage"
       :sort-by.sync="sortBy"
       :sort-desc.sync="sortDesc"
       :filter="filter"
       :filter-included-fields="filterOn"
       @filtered="onFiltered"
       :hover=true
       :foot-clone=clonefoot
      >
        <template #cell(status)="row">
          {{ (row.item.status === 1) ? 'Sim' : 'Não' }}
        </template>
        <template #cell(specialty)="row">
          <b-badge v-for="specialty in row.item.specialty" :key="specialty.id" variant="info" class="ml-1">{{ specialty.name }}</b-badge>
        </template>
        <template #cell(acoes)="row">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <b-dropdown v-bind:id="'dropdown-'+ row.item.id" class="m-md-2">
            <template #button-content>
              <i data-v-6289eca4="" class="bx ri-menu-line"></i>
            </template>
            <b-dropdown-item
             v-b-modal.modal-profissional
             @click="setProfessional(row.item)"
            >
              <i class="bx ri-file-search-line"></i> Visualizar
            </b-dropdown-item>
            <b-dropdown-item @click="editar(row.item)"><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item>
            <b-dropdown-item
             @click="excluir(row.item)"
            >
              <i class="bx ri-eraser-fill"></i> Excluir
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
   </div>
   <!-- End Table -->
   <!-- Start Pagination -->
  <div v-if="hide1" class="row mt-4">
    <div class="col">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Pagination -->
  <!-- Start Modal View -->
  <b-modal
    no-close-on-backdrop
    id="modal-profissional"
    title="Visualizar Profissional"
    title-class="font-18"
    ok-only
  >
    <Detail :currentProfessional="currentProfessional"/>
  </b-modal>
  <!-- End Modal View -->
</div>
</template>