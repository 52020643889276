<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <Label>Nome</Label>
          <input
            v-model="objeto_specialty.name"
            type="text"
            class="form-control text-left"
          />
        </div>
      </div>
      <div class="row mt-3 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click.prevent="enviar_value()">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    curretProf: { type: Object },
  },
  data() {
    return {
      name: "Ola",
      objeto_specialty: {},
    };
  },
  methods: {
    enviar_value() {
      this.objeto_specialty.prof_id = this.curretProf.id;
      this.objeto_specialty.empresa_id = this.curretProf.empresa_id;
      this.$emit("back_post_new_specialty", this.objeto_specialty);
    },
  },
};
</script>

<style>
</style>