<script>
console.clear();
import { notificationMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import { http } from "@/helpers/easyindustriaapi/config";
import addSpecialty from "./add_specialty.vue";
import Swal from "sweetalert2";

export default {
  props: {
    currentEmpresa: {
      type: Object,
      required: true,
    },
    currentProfessional: {
      type: Object,
      required: true,
    },
    vet_tratamentos: {
      type: Array,
    },
    users: {
      type: Array,
      required: true,
    }
  },
  components: { addSpecialty },
  data() {
    return {
      modal_specialty: false,
      vet_update_specialty: [],
      tratamentos: [],
      listUsers: [],
      loader: {
        get: false,
      },
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Lista de profissionais",
          href: "/",
          active: true,
        },
        {
          text: "Incluir profissionais",
          href: "/profissionais/insert",
          active: true,
        },
      ],
      professional: {
        name: null,
        email: null,
        contact: null,
        specialty: [],
        user_id: null,
        associated_user_id: null
      },
      logoUrl: "@/assets/crescer.png",
      titleBody: "Inserindo",
      inserindo: true,
      submitted: false,
      especialidade: [],
    };
  },
  validations: {
    currentProfessional: {
      name: {
        required,
      },
      email: {
        required,
      },
      contact: {
        required,
      },
      specialty: {
        required,
      },
    },
  },
  created() {
    this.tratamentos = this.vet_tratamentos;
    this.professional = this.currentProfessional;
    this.listUsers = this.users.map((user) => {
      return {
        text: user.name,
        value: user.id,
      };
    });

    if (Array.isArray(this.currentProfessional.specialty)) {
      this.especialidade = this.currentProfessional.specialty.map(
        (especialidade) => especialidade.id
      );
    }
  },
  mounted() {
    this.$emit("newTitle", "Incluir Profissional");
    this.professional = this.currentProfessional;
    this.vet_update_specialty = this.especialidade;
    this.novo_vetor_esspecialidade();
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    state() {
      return Boolean(this.currentProfessional.associated_user_id);
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    get_tratamet() {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando especialidades...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      const promisse = http.get("/specialty/pluck?empresa_id=" + this.currentEmpresa.id);

      return promisse.then((response) => {
        if (response.status === 200) {
          this.tratamentos = response.data;
          Swal.close(); // Fecha o loader
          return this.tratamentos || [];
        }
      }).catch((error) => {
        Swal.close(); // Fecha o loader
        Swal.fire('Erro', error.response.data.message, 'error');
      }).finally(() => {
      });
    },
    novo_vetor_esspecialidade() {
      const vet_tratamentos = this.tratamentos;

      const result = vet_tratamentos.map((specialty) => {
        if (this.especialidade.includes(specialty.id)) {
          return specialty.id; // Mantém a especialidade selecionada
        } else {
          return null; // Define id como 0 para especialidades não selecionadas
        }
      });

      this.especialidade = result;
    },
    add_specialty() {
      this.modal_specialty = true;
    },
    back_specialty() {
      this.modal_specialty = false;
    },
    back_post_new_specialty(professional) {
      this.modal_specialty = false;
      this.doPost(professional);
    },
    async get_show_prof(professional) {
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando dados Especialidades...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      const promisse = await http.get("/professional/show?prof_id=" + professional.prof_id);

      return promisse.then((response) => {
        if (response.status === 200) {
          const vet_prof = response.data;
          this.professional = vet_prof[0];
          this.professional = vet_prof[0];

          let especialidades_aux = vet_prof[0].specialty.map(
            (especialidade) => especialidade.id
          );

          const result = this.tratamentos.map((specialty) => {
            if (especialidades_aux.includes(specialty.id)) {
              return specialty.id; // Mantém a especialidade selecionada
            } else {
              return null; // Define id como 0 para especialidades não selecionadas
            }
          });

          this.especialidade = result;

          this.vet_update_specialty = vet_prof[0].specialty.map(
            (especialidade) => especialidade.id
          );

          return vet_prof || [];
        }
      }).catch((error) => {
        console.log(error);
        Swal.close(); // Fecha o loader
        Swal.fire('Oops...', error.response.data, 'error');
      });
    },
    doPost(professional) {
      const promisse = http.post("/professional/profSpec?empresa_id=" + this.currentEmpresa.id, professional);

      return promisse.then((response) => {
        if (response.status === 200) {
          this.get_tratamet();
          this.get_show_prof(professional);
          this.makeToast("success", "Registro incluído com sucesso!");
          return response.data;
        }
      }).catch((error) => {
        this.makeToast("danger", error.response);
        Swal.close();
        Swal.fire('Oops...', error.response.data, 'error');
      });

    },
    vetor_especialidades() {
      const especialidades = [
        { id: 1, name: "psicologia" },
        { id: 2, name: "fisioterapia" },
        { id: 3, name: "nutrição" },
      ];

      // Cria um novo vetor contendo apenas os IDs
      const idsEspecialidades = especialidades.map(
        (especialidade) => especialidade.id
      );

      console.log(idsEspecialidades); // [1, 2, 3]
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.currentProfessional.$error) {
        this.makeToast("danger", "Erro de validação!");
      } else {
        this.$emit("doPut", this.currentProfessional);
      }
    },
    setPut() {
      this.professional.vet_update_specialty = this.vet_update_specialty;
      this.$emit("doPut", this.professional);
    },
    select_specialty(item, index, event) {
      if (this.especialidade[index]) {
        this.especialidade[index] = parseInt(event.target.value);
        console.log(this.especialidade);
      } else {
        const updatedArray = this.professional.specialty.filter(
          (item) => item.id != event.target.value
        );
        console.log(this.especialidade);
        console.log(updatedArray);
      }

      // vet_back = this.especialidade
      const specialtyNumberVet = this.especialidade.filter(
        (item) => typeof item === "number"
      );

      // console.log(specialtyNumberVet)
      if (specialtyNumberVet.length == 0) {
        this.makeToast("danger", "selecione uma especialidade");
      }
      this.vet_update_specialt = [];
      this.vet_update_specialty = specialtyNumberVet;
      console.log(this.vet_update_specialty);
    },
    deatach() {
      this.professional.associated_user_id = null;
      this.currentProfessional.associated_user_id = null;
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formProfissional" id="formProfissional">
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="name">NOME DO PROFISSIONAL:</label>
            <input
              id="name"
              type="text"
              class="form-control"
              v-model="professional.name"
              maxlength="100"
              placeholder="Nome do Profissional"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="council">Conselho regional:</label>
            <input
              id="council"
              type="text"
              class="form-control"
              v-model="professional.council"
              maxlength="6"
              placeholder="Ex: CRM"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="credential">Credencial:</label>
            <input
              id="credential"
              type="number"
              class="form-control"
              v-model="professional.credential"
              maxlength="6"
              placeholder="Ex: "
            />
          </div>
          <div class="form-group col-md-6">
            <label for="email">E-MAIL:</label>
            <input
              id="email"
              type="email"
              class="form-control"
              v-model="professional.email"
              placeholder="Ex: profisional@email.com"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="contact">CONTATO TELEFÔNICO:</label>
            <input
              id="contact"
              type="tel"
              class="form-control"
              v-mask="'## #####-####'"
              v-model="professional.contact"
              placeholder="Ex: 12934567889"
            />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <label for="">Especialidades:</label>
            <div class="row">
              <div
                v-for="(item, index) in tratamentos"
                :key="index"
                class="col-md-4"
              >
                <div class="form-check">
                  <input
                    class="form-check-input"
                    v-model="especialidade[index]"
                    @change="select_specialty(item, index, $event)"
                    type="checkbox"
                    :id="'specialty-' + item.id"
                    :value="item.id"
                  />
                  <label
                    class="form-check-label"
                    for="specialty-neuropediatria"
                  >
                    {{ item.name }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click.prevent="add_specialty()">
              Adicionar Especiliade
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-modal
              v-model="modal_specialty"
              title="Adicionar Especialidade"
              title-class="font-18"
              @hidden="back_specialty()"
              hide-footer
            >
              <addSpecialty
                @back_post_new_specialty="back_post_new_specialty"
                :curretProf="currentProfessional"
              />
            </b-modal>
          </div>
        </div>
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-12">
            <label>Usuário associado:</label>
            <b-form-radio-group
             id="professional_associated_user_id"
             form="formProfissional"
             label="Usuário"
             v-model="professional.associated_user_id"
             :options="listUsers"
             :state="state"
             value-field="value"
             text-field="text"
             name="associated_user_id"
             class="mb-3"
            >
            </b-form-radio-group>
            <b-button v-if="state" variant="warning" @click.prevent="deatach()">
              <i class="ri-close-line align-middle mr-2"></i>Desassociar usuário
            </b-button>
          </div>
        </div>
        <div class="col-md-12 mt-4">
          <button
            class="btn btn-primary"
            style="margin-right: 20px"
            @click.prevent="setPut()"
          >
            Gravar
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- </layout> -->
</template>