<script>
console.clear();
import { notificationMethods } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    currentEmpresa: {
      type: Object,
      required: true,
    },
    vet_tratamentos: {
      type: Array,
    },
  },
  components: {},
  data() {
    return {
      tratamentos: [],
      loader: {
        get: false,
      },
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Lista de profissionais",
          href: "/",
          active: true,
        },
        {
          text: "Incluir profissionais",
          href: "/profissionais/insert",
          active: true,
        },
      ],
      profissional: {
        name: null,
        council: null,
        credential: null,
        email: null,
        contact: null,
        specialty: [],
      },
      logoUrl: "@/assets/crescer.png",
      titleBody: "Inserindo",
      inserindo: true,
      submit: false,
    };
  },
  validations: {
    profissional: {
      name: {
        required,
      },
      contact: {
        required,
      },
    },
  },
  created() {
    this.tratamentos = this.vet_tratamentos;
  },
  mounted() {
    this.$emit("newTitle", "Incluir Profissional");
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...notificationMethods,
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.profissional.$error) {
        this.makeToast("danger", "Erro de validação!");
      } else {
        this.$emit("doPost", this.profissional);
      }
    },
    select_specialty(objeto, index){
        console.log(objeto, index)
        console.log(this.profissional.specialty)
    },
    back() {
      this.$emit("back");
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="formSubmit" name="formProfissional">
        <div class="form-row col-md-12 mt-4">
          <div class="form-group col-md-4">
            <label for="name">Nome do profissional:</label>
            <b-form-input
              id="name"
              type="text"
              class="form-control"
              v-model="profissional.name"
              maxlength="100"
              placeholder="Nome do Profissional"
              :class="{ 'is-invalid': submit && $v.profissional.name.$error }"
            ></b-form-input>
            <div v-if="submit && $v.profissional.name.$error" class="invalid-tooltip">
              <span v-if="!$v.profissional.name.required">Por favor, informe o Nome do Profissional!</span>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="name">Conselho regional:</label>
            <input
              id="name"
              type="text"
              class="form-control"
              v-model="profissional.council"
              maxlength="6"
              placeholder="Ex: CRM"
            />
          </div>

          <div class="form-group col-md-4">
            <label for="name">Credencial:</label>
            <input
              id="name"
              type="number"
              class="form-control"
              v-model="profissional.credential"
              maxlength="6"
              placeholder="Ex: "
            />
          </div>

          <div class="form-group col-md-4">
            <label for="email">E-mail:</label>
            <input
              id="email"
              type="email"
              class="form-control"
              v-model="profissional.email"
              placeholder="Ex: profisional@email.com"
            />
          </div>

          <div class="form-group col-md-4">
            <label for="contact">Contato telefônico:</label>
            <b-form-input
              id="contact"
              type="tel"
              class="form-control"
              v-mask="'## #####-####'"
              v-model="profissional.contact"
              placeholder="Ex: 12934567889"
              :class="{ 'is-invalid': submit && $v.profissional.contact.$error }"
            ></b-form-input>
            <div v-if="submit && $v.profissional.contact.$error" class="invalid-tooltip">
              <span v-if="!$v.profissional.contact.required">Por favor, informe o Contato telefônico!</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label for="">Especialidades:</label>
            <div class="row">
              <div v-for="(item, index) in tratamentos" :key="index=item.id" class="col-md-4 ">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    v-model="profissional.specialty"
                    @change="select_specialty(item, index)"
                    type="checkbox"
                    :value="index"
                  />
                  <label
                    class="form-check-label"
                    for="specialty-neuropediatria"
                  >
                        {{item.name}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <fieldset class="form-group row mt-4">
          <legend class="col-form-label float-sm-left pt-0 font-weight-bold">
            Especialidades:
          </legend>
          <div class="col-md-4">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.neuropediatria"
                id="specialty-neuropediatria"
                value="NEUROPEDIATRIA"
              />
              <label class="form-check-label" for="specialty-neuropediatria">
                Neuropediatria
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.psicologia"
                id="specialty-psicologia"
                value="PSICOLOGO(A)"
              />
              <label class="form-check-label" for="specialty-psicologia">
                Psicologo(a)
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.aplicadoraba"
                id="specialty-aplicador-aba"
                value="APLICADOR ABA (AT)"
              />
              <label class="form-check-label" for="specialty-aplicador-aba">
                Aplicador ABA (AT)
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.supervisoraba"
                id="specialty-supervisoraba"
                value="SUPERVISOR ABA"
              />
              <label class="form-check-label" for="specialty-supervisoraba">
                Supervisor ABA
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.coordenadoraba"
                id="specialty-coordenadoraba"
                value="COORDENADOR ABA"
              />
              <label class="form-check-label" for="specialty-coordenadoraba">
                Coordenador ABA
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.psicopedagogo"
                id="specialty-psicopedagogo"
                value="PSICOPEDAGOGO(A)"
              />
              <label class="form-check-label" for="specialty-psicopedagogo">
                Psicopedagogo(a)
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.fisioterapeuta"
                id="specialty-fisioterapeuta"
                value="FISIOTERAPEUTA"
              />
              <label class="form-check-label" for="specialty-fisioterapeuta">
                Fisioterapeuta
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.terapeutaocupacional"
                id="specialty-terapeuta-ocupal"
                value="TERAPEUTA OCUPACIONAL"
              />
              <label
                class="form-check-label"
                for="specialty-terapeutaocupacional"
              >
                Terapeuta ocupacional
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.nutricionista"
                id="specialty-nutricionista"
                value="NUTRICIONISTA"
              />
              <label class="form-check-label" for="specialty-nutricionista">
                Nutricionista
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.psicomotricista"
                id="specialty-psicomotricista"
                value="PSICOMOTRICISTA"
              />
              <label class="form-check-label" for="specialty-psicomotricista">
                Psicomotricista
              </label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.neuropsicologo"
                id="specialty-neuropsicologo"
                value="NEUROPSICOLOGO(A)"
              />
              <label class="form-check-label" for="specialty-neuropsicologo">
                Neuropsicólogo(a)
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.musicoterapeuta"
                id="specialty-musicoterapeuta"
                value="MUSICOTERAPEUTA"
              />
              <label class="form-check-label" for="specialty-musicoterapia">
                Musicoterapeuta
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.psiquiatra"
                id="specialty-psiquiatra"
                value="PSIQUIATRA"
              />
              <label class="form-check-label" for="specialty-psiquiatra">
                Psiquiatra
              </label>
            </div>
            <div class="form-check disabled">
              <input
                class="form-check-input"
                v-model="profissional.specialty"
                type="checkbox"
                name="specialty.hidroterapeuta"
                id="specialty-hidroterapeuta"
                value="HIDROTERAPEUTA"
              />
              <label class="form-check-label" for="specialty-hidroterapia">
                Hidroterapeuta
              </label>
            </div>
          </div>
        </fieldset> -->

        <div class="col-md-12 mt-4">
          <button
            type="submit"
            class="btn btn-primary"
            style="margin-right: 20px"
          >
            Cadastrar
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- </layout> -->
</template>